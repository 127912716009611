import { Puzzle } from "./Game";
import hcJson from './hardcoded.json';

function convertToPuzzle(data: any): Puzzle {
    return {
        targets: data.targets as [string, string],
        initialGuesses: data.initialGuesses,
        puzzleCredit: data.puzzleCredit,
        imageCredit: data.imageCredit,
        limerick: data.limerick,
        referralLink: data.referralLink,
        referralText: data.referralText
    };
}

export const hardCodedPuzzles: Record<number, Puzzle> = Object.fromEntries(
    Object.entries(hcJson).map(([key, value]) => [Number(key), convertToPuzzle(value)])
);