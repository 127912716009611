import { GameState, imageUrls, makePuzzle } from "./Game";
import { GetDaynum, Day } from "./Stats";
import { todayDayNum, spoilers } from "./util";

export function GetDaySpoilers(day: number) : Day | null {
  return  { 
    puzzle: makePuzzle(day),
    gameState: GameState.Won,
    guesses: [],
    hardMode: false
  }
}

interface GalleryProps {
  author: string;
}

export function Gallery(props: GalleryProps) {
  const galleryDivs = new Array(todayDayNum).fill(undefined)
  .map((_,i) => {
    let spoilersDay = GetDaySpoilers(i+1);
    let day = spoilers ? spoilersDay : GetDaynum(i+1);
    let linkurl = "?x=" + (i+1);
    const byAuthor = props.author === "" || 
      (spoilersDay && spoilersDay.puzzle.puzzleCredit && spoilersDay.puzzle.puzzleCredit == props.author);

    if (!byAuthor) {
      return undefined;
    }

    if ( !day || (day.gameState === GameState.Playing )) {
      return (
        <div>
          <p><a href={linkurl}>Day {i+1}</a></p>
          <p>Not Played</p>
          <hr/>
        </div>);
    }
    let [preview, original] = imageUrls(i+1);
    return (
      <div>
        <p><a href={linkurl}>Day {i+1}</a></p>
        <p>        
        {day.puzzle.initialGuesses.map(s=>s.toUpperCase()).join(", ")} » {day.puzzle.targets[0].toUpperCase()} + {day.puzzle.targets[1].toUpperCase()}</p>
        <a className="rewardImageLink" href={original} target="_blank">
          <img className="rewardImage" src={preview}/>
        </a>
        {props.author === "" && day.puzzle.puzzleCredit && <div>Submitted by {day.puzzle.puzzleCredit}</div>}
        <hr/>
      </div>
    );
  }).reverse();
  const authorProps = props.author !== "" && (<h2 className="gallery-author">{props.author}</h2>);
  return (
    <div className="Gallery">
        {authorProps}
        {galleryDivs}
    </div>
  );
}
