export const nightmares : number[] = [
    1120, 
    1550, 
    2051,    
    5714, 
    6545, 
    9869, 
    2741,
    3542,
    10799,
    5714,
    5864,
    4313,
    5874,
    6545,
    4113,
    9388,
    6945,
    3542,
    3502,
    7236,
    10879,
    4423,
    2401,
    4913,
    9648,
    7966,
    2061,
    2861,
    10759,
    9608,
    4343,
    4393,
    4013,
    1340,
    7006,
    2511,
    4263,
    5584,
    3242,
    10379,
];
