import { deserializeStorage, serializeStorage } from "./App";
import { copyImportCode } from "./Game";
import { gameName, maxGuesses } from "./util";
import React, { useRef, useEffect, useState } from 'react';

export function About() {
  const [importCode, setImportCode] = useState('');
  const importCodeRef = useRef<HTMLTextAreaElement>(null);
  const isHttp = window.location.protocol === "http:";

  const handleTextareaClick = () => {
    if (importCodeRef.current) {
      importCodeRef.current.select();
    }
  };

  useEffect(() => {
    if (importCodeRef.current) {
      importCodeRef.current.value = serializeStorage();
    }
  }, []);

  const handleImport = () => {
    if (importCode.trim() !== '') {
      deserializeStorage(importCode);
      alert('Import successful! Your game history has been updated.');
      setImportCode('');
    } else {
      alert('Please enter an import code before importing.');
    }
  };

  return (
    <div className="App-about">
        <p>
          {gameName} is a variant of Wordle by Josh Wardle and is one of a number of sibling sites.{" "}
          <br /><br /> <a href="https://xordle.org">Xordle</a> by <a href="https://twitter.com/kellydornhaus">keldor</a><br/>Two secret words, one board, no overlap between the words. 
          <br /><br /> <a href="https://fibble.xyz">Fibble</a> by K &amp; R Garfield, coded by keldor <br/>Lies to you once per row.
          <br /><br /> <a href="https://double-dabble.com">Double-Dabble</a> by K &amp; R Garfield, coded by keldor <br/>Each letter is bound to another letter.
          <br /><br /> <a href="https://warmle.org">Warmle</a> by Mike Elliott, coded by keldor <br/>Yellows tell you if you've gotten close in that position.
          <br /><br /> <a href="https://shuntle.com">Shuntle</a> by LSK, coded by keldor<br/>The secret word is 4-7 letters long, with spaces on the left or right.
          <br /><br />Code based on a fork of <a href="https://github.com/lynn/hello-wordl">hello wordl</a>.
        </p>
      <hr />        
      <p className="App-instructions">
        <h1>Xordle Rules!</h1>
        It certainly does.
        <br />There are two secret words.
        <br /><b>They share no letters in common.</b>
        <br />You get {maxGuesses-1} tries to guess both words.
        <br />
        <br />You start with a clue already given. It's the same for everyone. 
        <br />The daily puzzle is often themed, but not always, and the theme is often not obvious.
        <br />
        <br />Letters in your guess are:
        <br />🟩 Green if green in either word (right letter, right spot).
        <br />🟨 Yellow if yellow in either word (right letter, wrong spot).
        <br />⬛ Grey if it does not appear in either word.
        <br />
        <p>
            Or, if you prefer your game rules in song form, press play:
            <video width="400" controls>
                <source src="./videos/ThereAreTwoSecretWordsXordleOdyssey.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </p>
            Audio and additional lyrics generated by udio AI with prompting by keldor
      </p>
      <hr />      
      <h1>Daily puzzle images</h1>
      Images are generated using the Midjourney or Flux AI.
      <hr />
      <p>
        <h1>User submissions and contact info</h1>
        Feel free to send in puzzle suggestions using <a href="https://forms.gle/CMc6BAzwjzMWqgS17">this form</a>.  
        You can report game issues{" "}
        <a href="https://github.com/6zs/xordle/issues">here</a> or send me an <a href="mailto:kellydornhaus@gmail.com">email</a> about whatever is on your mind.<br/>        
        <br />Keldor blog exists at <a href="https://keldor.org">keldor.org</a> and includes image galleries of past puzzles.        
      </p>
      <div>
        <hr/>
        <h2>Transferring game history to a new device or browser</h2>
        <p>
          <button onClick={() => {copyImportCode();}}>Copy Export Code</button>
          {' '}Click to copy your current game history to the clipboard.
        </p>
        
        <p>Export Code (read-only):</p>
        <textarea 
          ref={importCodeRef}
          readOnly 
          rows={5} 
          cols={50} 
          onClick={handleTextareaClick}
          style={{width: '100%', maxWidth: '600px'}}
        />

        <p>Import Code:</p>
        <textarea 
          value={importCode}
          onChange={(e) => setImportCode(e.target.value)}
          rows={5} 
          cols={50} 
          placeholder="Paste your import code here"
          style={{width: '100%', maxWidth: '600px'}}
        />
        <br/>
        <button onClick={handleImport}>Import Game History</button>
      </div>
      <p>
      </p>
    </div>
  );
}
